#hero {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.video-container {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

video {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.video-container:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 1;
}

.overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    height: 100%;
    text-align: left;
    position: relative;
    z-index: 2;
    width: 70%;
    margin: auto;
}