@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Futura';
    src: url('./assets/fonts/futur.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura Medium';
    src: url('./assets/fonts/futura\ medium\ bt.ttf') format('truetype');
}  

@font-face {
    font-family: 'Futura Light';
    src: url('./assets/fonts/futura\ light\ bt.ttf') format('truetype');
}  

.text {
    font-family: 'Futura Light';
    font-weight: 500;
}

.text-medium {
    font-family: 'Futura Medium';
    font-weight:500;
}